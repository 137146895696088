<template>
  <Account :reAuth="reAuth" />
</template>

<script>
import { mapActions } from 'vuex'
import Account from '@/components/account'
import { EVENT_NAMES, analyticsEvent } from '@/helpers/analytics'

export default {
  components: {
    Account
  },
  methods: {
    ...mapActions('auth', ['signOut']),
    async reAuth () {
      await this.signOut()
      window.location.reload()
    }
  },
  mounted () {
    analyticsEvent(EVENT_NAMES.LOADED_ACCOUNT)
  }
}
</script>
