<template>
  <v-container id="account">
    <LoadingDialog v-if="linking" />
    <v-row justify="center">
      <v-col>
        <v-card>
          <v-alert tile type="error" v-if="errorMessage">{{errorMessage}}</v-alert>
          <v-card-title>
            <v-spacer />
            {{$t('m.account.title')}}
            <v-spacer />
          </v-card-title>
          <v-row justify="center">
            <input type="file" ref="fileInput" v-show="false" accept="image/*" @change="onChange" />
            <v-col cols="auto">
              <v-avatar
                class="elevation-6"
                :style="{cursor: uploading ? 'auto' : 'pointer'}"
                :color="userImage ? null : 'teal'"
                size="128"
                @click="uploading ? ()=>{} : $refs.fileInput.click()"
              >
                <v-img v-if="userImage" :src="userImage">
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular indeterminate color="blue lighten-2"></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <v-icon x-large v-else class="white--text">mdi-camera-plus</v-icon>
                <v-fade-transition>
                  <v-overlay v-if="uploading" absolute color="grey darken-3" />
                </v-fade-transition>
              </v-avatar>
              <v-progress-linear class="mt-3" v-if="uploading" indeterminate color="teal"></v-progress-linear>
            </v-col>
          </v-row>
          <v-row justify="center" class="no-wrap">
            <v-col>
              <ProfileForm />
            </v-col>
          </v-row>
          <v-row justify="center" class="no-wrap" v-if="!isProd">
            <v-col>
              <SyncToCalendar />
            </v-col>
          </v-row>

          <v-divider />
          <v-row justify="center" v-if="!isRecentlyAuthed">
            <v-col cols="12" sm="8">
              <v-alert tile elevation="2" border="top" size="small" colored-border type="info">
                {{$t('m.account.loginAgain')}}
                <div>
                  <v-btn @click="reAuth">{{$t('m.account.reauth')}}</v-btn>
                </div>
              </v-alert>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" sm="8" md="6">
              <v-list subheader>
                <v-subheader>{{$t('m.account.accountLink')}}</v-subheader>
                <template v-for="(provider, index) in logins">
                  <v-divider v-if="index > 0" :key="`${provider.providerId}-divider`" />
                  <v-list-item :key="`${provider.providerId}-listitem`">
                    <v-list-item-avatar>
                      <v-icon>{{provider.icon}}</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content v-if="!provider.linked">
                      <v-list-item-title>&nbsp;</v-list-item-title>
                    </v-list-item-content>
                    <template v-if="!provider.linked">
                      <v-list-item-action v-if="provider.providerId === ACCOUNT_LOGIN_TYPES.email">
                        <v-dialog max-width="500" v-model="emailLinkDialog" persistent>
                          <template v-slot:activator="{ on }">
                            <v-btn :disabled="!isRecentlyAuthed" v-on="on">{{$t('m.account.link')}}</v-btn>
                          </template>
                          <EmailLinker @close="emailLinkDialog = false" />
                        </v-dialog>
                      </v-list-item-action>
                      <v-list-item-action
                        v-else-if="provider.providerId === ACCOUNT_LOGIN_TYPES.phone"
                      >
                        <v-dialog max-width="500" v-model="phoneLinkDialog" persistent>
                          <template v-slot:activator="{ on }">
                            <v-btn :disabled="!isRecentlyAuthed" v-on="on">{{$t('m.account.link')}}</v-btn>
                          </template>
                          <PhoneLinker @close="phoneLinkDialog = false" />
                        </v-dialog>
                      </v-list-item-action>
                      <v-list-item-action v-else>
                        <v-btn
                          :disabled="!isRecentlyAuthed"
                          @click="onLink(provider.providerId)"
                        >{{$t('m.account.link')}}</v-btn>
                      </v-list-item-action>
                    </template>
                    <v-list-item-content v-if="provider.linked">
                      <v-list-item-title v-text="provider.identifier"></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action v-if="provider.linked && allowRemoval">
                      <v-btn
                        color="warn"
                        :disabled="!isRecentlyAuthed"
                        @click="onUnlink(provider.providerId)"
                      >{{$t('m.account.unlink')}}</v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-list>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { ACCOUNT_LOGIN_TYPES } from '@/enums'
import LoadingDialog from '@/components/LoadingDialog'
import ProfileForm from './ProfileForm'
import SyncToCalendar from './SyncToCalendar'
import { fromUnixTime, differenceInMinutes } from 'date-fns'
import errorLookups from './errors'

function normalizeProviderIdentifier (singleProvider) {
  switch (singleProvider.providerId) {
    case ACCOUNT_LOGIN_TYPES.phone:
      return singleProvider.phoneNumber
    case ACCOUNT_LOGIN_TYPES.facebook:
      return singleProvider.displayName
    case ACCOUNT_LOGIN_TYPES.google:
    case ACCOUNT_LOGIN_TYPES.email:
      return singleProvider.email
  }
}

function normalizeProviderIcon (providerId) {
  switch (providerId) {
    case ACCOUNT_LOGIN_TYPES.phone:
      return 'mdi-phone'
    case ACCOUNT_LOGIN_TYPES.facebook:
      return 'mdi-facebook'
    case ACCOUNT_LOGIN_TYPES.google:
      return 'mdi-google'
    case ACCOUNT_LOGIN_TYPES.email:
      return 'mdi-email'
  }
}

function normalizeProviderData (providerData, loginTypes) {
  const providers = process.env.VUE_APP_PROVIDERS.split(',')
  const accountLoginTypes = providers.map(provider => [provider, ACCOUNT_LOGIN_TYPES[provider]])

  return accountLoginTypes.map(([key, value]) => {
    const existingProvider = providerData.find(({ providerId }) => providerId === value)
    return {
      providerId: value,
      identifier: existingProvider ? normalizeProviderIdentifier(existingProvider) : undefined,
      linked: !!existingProvider,
      icon: normalizeProviderIcon(value)
    }
  })
    .sort((a, b) => a.linked ? -1 : 1)
}

export default {
  components: {
    LoadingDialog,
    ProfileForm,
    SyncToCalendar,
    EmailLinker: () => import('./EmailLinker'),
    PhoneLinker: () => import('./PhoneLinker')
  },
  props: {
    reAuth: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      uploading: false,
      linking: false,
      emailLinkDialog: false,
      phoneLinkDialog: false,
      now: new Date(),
      nowUpdater: null,
      ACCOUNT_LOGIN_TYPES,
      isProd: process.env.VUE_APP_STAGE === 'prod' || process.env.VUE_APP_STAGE === 'demo'
    }
  },
  computed: {
    ...mapState('auth', ['userImage', 'providerData', 'authTime', 'snsLinkLoginErrorCode']),
    logins () {
      return this.providerData ? normalizeProviderData(this.providerData, ACCOUNT_LOGIN_TYPES) : []
    },
    allowRemoval () {
      return (this.logins.filter(i => i.linked)).length > 1
    },
    isRecentlyAuthed () {
      if (this.authTime) {
        const authDate = fromUnixTime(this.authTime)
        const diffMinutes = differenceInMinutes(this.now, authDate)
        return diffMinutes <= 5
      } else {
        return false
      }
    },
    errorMessage () {
      const lookupResult = errorLookups[this.snsLinkLoginErrorCode]
      return lookupResult || this.snsLinkLoginErrorCode
    }
  },
  methods: {
    ...mapActions('auth', ['updateUserImage', 'linkProvider', 'unlinkProvider']),
    async onChange (event) {
      try {
        const { target: { files: [file] } } = event
        this.uploading = true
        await this.updateUserImage(file)
      } catch (err) {
        console.error('error uploading user photo', err) // eslint-disable-line no-console
      } finally {
        this.uploading = false
      }
    },
    onLink (providerId) {
      this.linking = true
      this.linkProvider(providerId)
    },
    async onUnlink (providerId) {
      this.linking = true
      try {
        await this.unlinkProvider(providerId)
      } catch (err) {
        console.error(`error unlinking ${providerId}`, err) // eslint-disable-line no-console
      } finally {
        this.linking = false
      }
    }
  },
  mounted () {
    this.nowUpdater = setInterval(() => { this.now = new Date() }, 30000)
  },
  destroyed () {
    if (this.nowUpdater) {
      clearInterval(this.nowUpdater)
    }
  }
}
</script>

<style>
</style>
