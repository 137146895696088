<template>
  <div>
    <v-sheet color="secondary lighten-1" tile>
      <v-card-title>{{ $t("m.addToCalendar.sync.title") }}</v-card-title>
    </v-sheet>
    <v-card>
      <v-list>
        <template v-for="(item, i) in availableItems">
          <v-list-item
            :key="i"
            :download="item.ics ? 'calendar.ics' : undefined"
            @click="onSyncMenuClick(item)"
            target="_blank"
            :disabled="!schedulerlySignedIn"
            v-if="!item.linked"
          >
            <v-list-item-avatar>
              <v-img :src="item.icon"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t(item.title) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            :key="i"
            :download="item.ics ? 'calendar.ics' : undefined"
            target="_blank"
            :disabled="!schedulerlySignedIn"
            v-else
          >
            <v-list-item-avatar>
              <v-img :src="item.icon"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t(item.title) }}
                {{ item.linked ? $t("m.addToCalendar.sync.linked") : "" }}
              </v-list-item-title>
            </v-list-item-content>
            <v-btn @click="onSignoutClick(item)">
              {{ $t("m.drawer.signOut") }}
            </v-btn>
          </v-list-item>
        </template>
      </v-list>
    </v-card>
  </div>
</template>

<script>

import { schedulerlyClient } from '@/helpers/schedulerly'
import { createNamespacedHelpers } from 'vuex'
const { mapActions: mapActionsCalendar } = createNamespacedHelpers('calendar')

export default {
  components: {
  },
  mounted () {
    this.authenticateSchedulerlyClient()
      .then(() => {
        this.schedulerlySignedIn = true
        schedulerlyClient
          .isLinkedWithGoogle()
          .then((linked) => {
            this.items[0].linked = linked
          })
        schedulerlyClient
          .isLinkedWithMicrosoft()
          .then((linked) => {
            this.items[1].linked = linked
          })
      })
  },
  methods: {
    ...mapActionsCalendar(['authenticateSchedulerlyClient']),
    onSignoutClick (item) {
      if (!item.linked) {
        return
      }

      if (item.link === 'googleCalendar') {
        return schedulerlyClient
          .signOutOfGapi()
          .then(() => {
            this.items[0].linked = false
          })
      } else if (item.link === 'outlookCalendar') {
        return schedulerlyClient
          .signOutOfMicrosoft()
          .then(() => {
            this.items[1].linked = false
          })
      }
    },
    onSyncMenuClick (item) {
      if (item.linked) {
        return
      }
      if (item.link === 'googleCalendar') {
        return schedulerlyClient
          .linkWithGoogle()
          .then(() => {
            this.items[0].linked = true
          })
      } else if (item.link === 'outlookCalendar') {
        return schedulerlyClient
          .linkWithMicrosoft()
          .then(() => {
            this.items[1].linked = true
          })
      }
    }
  },
  computed: {
    availableItems () {
      return this.items.filter(({ comingSoon }) => !comingSoon)
    }
  },
  data () {
    return {
      schedulerlySignedIn: false,
      items: [{
        title: 'm.addToCalendar.googleCalendar',
        icon: '/images/logos/google_calendar.png',
        link: 'googleCalendar',
        linked: false
      }, {
        title: 'm.addToCalendar.outlookCalendar',
        icon: '/images/logos/outlook_calendar.png',
        link: 'outlookCalendar',
        linked: false
      }, {
        title: 'm.addToCalendar.yahooCalendar',
        icon: '/images/logos/yahoo.png',
        link: 'yahooCalendar',
        comingSoon: true
      }, {
        title: 'm.addToCalendar.appleCalendar',
        icon: '/images/logos/apple_calendar.png',
        link: 'appleCalendar',
        ics: true,
        comingSoon: true
      }]
    }
  }
}
</script>
