<template>
  <ValidationObserver ref="observer" v-slot="{ invalid }">
    <v-sheet color="secondary lighten-1" tile>
      <v-card-title>{{$t('m.account.profile.title')}}</v-card-title>
    </v-sheet>
    <v-card :loading="saving" :elevation="0">
      <v-card-text>
        <ValidationProvider
          :name="$t('m.account.profile.givenName')"
          rules="required"
          v-slot="{ errors }"
        >
          <v-text-field
            :error-messages="errors"
            v-model="givenName"
            :label="$t('m.account.profile.givenName')"
          />
        </ValidationProvider>
      </v-card-text>
      <v-card-text>
        <ValidationProvider
          :name="$t('m.account.profile.firstName')"
          rules="required"
          v-slot="{ errors }"
        >
          <v-text-field
            :error-messages="errors"
            v-model="firstName"
            :label="$t('m.account.profile.firstName')"
          />
        </ValidationProvider>
      </v-card-text>
      <v-card-text>
        <ValidationProvider
          :name="$t('m.account.profile.email')"
          rules="required|email"
          v-slot="{ errors }"
        >
          <v-text-field
            :error-messages="errors"
            v-model="email"
            :label="$t('m.account.profile.email')"
          />
        </ValidationProvider>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          :disabled="invalid"
          :loading="saving"
          @click="onSubmit"
        >{{$t('m.account.profile.save')}}</v-btn>
      </v-card-actions>
    </v-card>
  </ValidationObserver>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapState, mapActions, mapGetters } from 'vuex'
export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data () {
    return {
      firstName: null,
      givenName: null,
      email: null,
      saving: false
    }
  },
  computed: {
    ...mapState('auth', ['uid']),
    ...mapGetters('auth', ['profile'])
  },
  methods: {
    ...mapActions('auth', ['updateProfile']),
    copyProfileToForm () {
      this.firstName = this.profile.firstName
      this.givenName = this.profile.givenName
      this.email = this.profile.email
    },
    async onSubmit () {
      this.saving = true
      try {
        const isValid = await this.$refs.observer.validate()
        if (isValid) {
          await this.updateProfile({ uid: this.uid, firstName: this.firstName, givenName: this.givenName, email: this.email })
        }
      } catch (err) {
        console.error('error updating profile', err) // eslint-disable-line no-console
      } finally {
        this.saving = false
      }
    }
  },
  mounted () {
    this.copyProfileToForm()
  }
}
</script>

<style>
</style>
